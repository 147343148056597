body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #f0fcff;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
.slider-frame {
	height: 100%;
}
.slider-container {
	height: 100%;
}
.paging-dot {
	height: 0.7rem !important;
	width: 0.7rem !important;
}
g&:focus,
path&:focus {
	outline: none !important;
}
.recharts-wrapper > svg > g {
	outline: none !important;
}
.recharts-layer {
	outline: none !important;
}
.recharts-pie-sector > path {
	outline: none !important;
}
